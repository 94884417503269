//clients
export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL"

//client data
export const GET_CLIENT_DATA = "GET_CLIENT_DATA"
export const GET_CLIENT_DATA_SUCCESS = "GET_CLIENT_DATA_SUCCESS"
export const GET_CLIENT_DATA_FAIL = "GET_CLIENT_DATA_FAIL"

//contracts
export const GET_CONTRACTS = "GET_CONTRACTS"
export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_SUCCESS"
export const GET_CONTRACTS_FAIL = "GET_CONTRACTS_FAIL"