import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD } from "./actionTypes"
import { userChangePasswordSuccess, userChangePasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeChangePwd,
  postJwtChangePwd,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* changeUser({ payload: { user, history } }) {
  /*try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.changePassword, user.email)
      if (response) {
        yield put(
          userChangePasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtChangePwd, "/jwt-change-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userChangePasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else {
      const response = yield call(postFakeChangePwd, "/fake-change-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userChangePasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    }
  } catch (error) {
    yield put(userChangePasswordError(error))
  }*/
  try {
    const response = yield call(postFakeChangePwd, {
      password1: user.password1,
      password2: user.password2,
      customer: user.customer,
    });
    console.log("essppspss",response)
    if(response.status){
      //localStorage.setItem("authUser", JSON.stringify(response));
      yield put(userChangePasswordSuccess(response));
      history.push("/contratti");
    }else{
      alert("Dati errati")
    }
  } catch (error) {
    yield put(userChangePasswordError(error))
  }
}

function* changePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD, changeUser)
}

export default changePasswordSaga;
