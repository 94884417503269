import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = "https://my.tecnodatasystem.eu:8080/v1"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

axios
  .get("https://my.tds.srl/v1/apihelper/index.php")
  .then(response => {
    console.log("response",response.data)
    const serverIp = response.data.serverIp
    const clientIp = response.data.clientIp
    if (clientIp === "185.76.59.10" || serverIp === "54.38.125.181") {
      axiosApi.defaults.baseURL = "https://my.tecnodatasystem.eu/v1"
    } else {
      axiosApi.defaults.baseURL = "https://my.tecnodatasystem.eu:8080/v1"
    }
  })
  .catch(error => {
    console.error("Error fetching IP address:", error)
  })
