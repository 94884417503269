import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_FAIL,
  GET_CLIENT_DATA_SUCCESS,
  GET_CLIENT_DATA_FAIL
} from "./actionTypes"

const INIT_STATE = {
  clients: [],
  contracts: [],
  client_data: {},
  error: {},
}

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      }

    case GET_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.payload,
      }

    case GET_CONTRACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CLIENT_DATA_SUCCESS:
      return {
        ...state,
        client_data: action.payload,
      }

    case GET_CLIENT_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Clients
