import React, { useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Badge,
} from "reactstrap"
import Select from "react-select"

//Import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"

import { getContracts, getClientData } from "../../store/actions"

const Contratti = () => {
  //meta title
  document.title = "Contratti | myTDS"

  const [client, setClient] = useState(null)

  const dispatch = useDispatch()

  const [user, setUser] = useState({})
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUser(obj)
      setClient(obj.fk_customer_id)
    }
  }, [])

  const { contracts } = useSelector(state => ({
    contracts: state.Clients.contracts,
  }))

  const columns = useMemo(() => {
    if (client && contracts.length > 0) {
      var x = contracts[0]
      var y = []
      Object.keys(x).forEach(key => {
        y.push({
          Header: key,
          accessor: key,
          Cell: ({ cell: { value } }) => {
            switch (key) {
              case "Status":
                return getStatus(value)
                break

              case "Da":
                return switchData(value)
                break

              case "A":
                return switchData(value)
                break

              case "Importo":
                return "€ " + value.toFixed(2)
                break

              case "PU":
                return "€ " + value.toFixed(2)
                break

              default:
                return value
            }
          },
        })
      })
      return y
    }
  }, [client, contracts])

  const switchData = date => {
    var x = date.split("-")
    return x[2] + "/" + x[1] + "/" + x[0]
  }

  const getStatus = val => {
    var nomeTab
    switch (val) {
      case "IN ATTIVAZIONE":
        nomeTab = <Badge color="info">{val}</Badge>
        break

      case "ATTIVO":
        nomeTab = <Badge color="success">{val}</Badge>
        break

      case "DISDETTO":
        nomeTab = <Badge color="danger">{val}</Badge>
        break

      case "SOSPESO":
        nomeTab = <Badge color="warning">{val}</Badge>
        break

      case "SOSTITUITO":
        nomeTab = <Badge color="primary">{val}</Badge>
        break

      case "SCADUTO":
        nomeTab = <Badge color="dark">{val}</Badge>
        break

      default:
        nomeTab = <Badge color="dark">CONTRATTO NON CLASSIFICATO</Badge>
    }
    return nomeTab
  }

  //get contratti in base a selezione cliente da select
  useEffect(() => {
    if (client) {
      dispatch(getContracts(client, user.role))
      dispatch(getClientData(client))
    }
  }, [dispatch, client])

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Contratti" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle>Situazione Cliente</CardTitle>
                    <Col sm="12">
                      <CardText className="mb-0">
                        {client && contracts.length > 0 ? (
                          <TableContainer
                            columns={columns}
                            data={contracts}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            customPageSize={50}
                            className="custom-header-css"
                          />
                        ) : (
                          <></>
                        )}
                      </CardText>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default Contratti
