import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_CLIENTS,
  GET_CONTRACTS,
  GET_CLIENT_DATA
} from "./actionTypes";
import {
  getClientsFail,
  getClientsSuccess,
  getContractsFail,
  getContractsSuccess,
  getClientDataFail,
  getClientDataSuccess
} from "./actions";

//Include Both Helper File with needed methods
import {
  getClients,
  getContracts,
  getClientData
} from "../../helpers/fakebackend_helper";

function* fetchClients() {
  try {
    const response = yield call(getClients);
    yield put(getClientsSuccess(response));
  } catch (error) {
    yield put(getClientsFail(error));
  }
}

function* fetchClientData({ payload: client }) {
  try {
    const response = yield call(getClientData, client);
    yield put(getClientDataSuccess(response));
  } catch (error) {
    yield put(getClientDataFail(error));
  }
}

function* fetchContracts({ payload: {client, role} }) {
  try {
    const response = yield call(getContracts, client, role);
    yield put(getContractsSuccess(response));
  } catch (error) {
    yield put(getContractsFail(error));
  }
}

function* clientsSaga() {
  yield takeEvery(GET_CLIENTS, fetchClients);
  yield takeEvery(GET_CLIENT_DATA, fetchClientData)
  yield takeEvery(GET_CONTRACTS, fetchContracts)
}

export default clientsSaga;