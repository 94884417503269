import PropTypes from "prop-types"
import React from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
//import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.png"

//Import config
import { facebook, google } from "../../config"

const Login = props => {
  //meta title
  document.title = "Login | myTDS"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Inserisci codice utente"),
      password: Yup.string().required("Inserisci password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  /*const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    }
  };*/

  //handleGoogleLoginResponse
  /*const googleResponse = response => {
    signIn(response, "google");
  };*/

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  /*const facebookResponse = response => {
    signIn(response, "facebook");
  };*/

  return (
    <div className="bodyBackground">
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card
                  className="overflow-hidden"
                  style={{
                    backgroundColor: "rgba(245, 245, 245, 0.4)",
                    borderRadius: 0,
                  }}
                >
                  <div className="">
                    <Row>
                      {/* <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">new myTDS</h5>
                        <p>Accedi per continuare.</p>
                      </div>
                    </Col> */}
                      <Col className="center">
                        <img
                          src={logo}
                          alt=""
                          className="img-fluid mx-auto d-block"
                          style={{ maxWidth: "50%", margin: "20px 0 20px 0" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    {/* <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label">Codice utente</Label>
                          <Input
                            style={{
                              backgroundColor: "transparent",
                              color: "white",
                            }}
                            name="email"
                            className="form-control"
                            type="default"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            style={{
                              backgroundColor: "transparent",
                              color: "white",
                            }}
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-secondary btn-block"
                            type="submit"
                          >
                            Accedi
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li> */}
                        {/*<li className="list-inline-item">*/}
                        {/*  <TwitterLogin*/}
                        {/*    loginUrl={*/}
                        {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                        {/*    }*/}
                        {/*    onSuccess={this.twitterResponse}*/}
                        {/*    onFailure={this.onFailure}*/}
                        {/*    requestTokenUrl={*/}
                        {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                        {/*    }*/}
                        {/*    showIcon={false}*/}
                        {/*    tag={"div"}*/}
                        {/*  >*/}
                        {/*    <a*/}
                        {/*      href=""*/}
                        {/*      className="social-list-item bg-info text-white border-info"*/}
                        {/*    >*/}
                        {/*      <i className="mdi mdi-twitter"/>*/}
                        {/*    </a>*/}
                        {/*  </TwitterLogin>*/}
                        {/*</li>*/}
                        {/* <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => { }}
                            />
                          </li>
                        </ul>
                      </div> */}

                        {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                {/* <div className="mt-5 text-center">
                
                <p>
                  © {new Date().getFullYear()} myTDS. Sviluppato con{" "}
                  <i className="mdi mdi-heart text-danger" /> da TDS S.r.l.
                </p>
              </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
