import React, { useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Badge,
} from "reactstrap"
import Select from "react-select"

//Import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"

import { getClients, getContracts, getClientData } from "../../store/actions"
import classnames from "classnames"
import RadarChart from "pages/AllCharts/chartjs/radarchart"

const Clienti = () => {
  //meta title
  document.title = "Clienti | myTDS"

  const [client, setClient] = useState(null)
  const [clientList, setClientList] = useState([])
  const [contractsList, setContractsList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [activeTab, setactiveTab] = useState("1")
  const [activeTab1, setactiveTab1] = useState("4")
  const [activeTab2, setactiveTab2] = useState("1")
  const [activeTab3, setactiveTab3] = useState("1")

  const dispatch = useDispatch()

  const [user, setUser] = useState({})
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUser(obj)
    }
  }, [])

  const { clients, client_data, contracts } = useSelector(state => ({
    clients: state.Clients.clients,
    contracts: state.Clients.contracts,
    client_data: state.Clients.client_data,
  }))

  const columns = useMemo(() => {
    if (client && contracts.length > 0) {
      var x = contracts[0]
      var y = []
      Object.keys(x).forEach(key => {
        y.push({
          Header: key,
          accessor: key,
          Cell: ({ cell: { value } }) => {
            switch (key) {
              case "Status":
                return getStatus(value)
                break

              case "Da":
                return switchData(value)
                break

              case "A":
                return switchData(value)
                break

              case "Importo":
                return "€ " + value.toFixed(2)
                break

              case "PU":
                return "€ " + value.toFixed(2)
                break

              default:
                return value
            }
          },
        })
      })
      return y
    }
  }, [client, contracts])

  const switchData = date => {
    var x = date.split("-")
    return x[2] + "/" + x[1] + "/" + x[0]
  }

  const getStatus = val => {
    var nomeTab
    switch (val) {
      case "IN ATTIVAZIONE":
        nomeTab = <Badge color="info">{val}</Badge>
        break

      case "ATTIVO":
        nomeTab = <Badge color="success">{val}</Badge>
        break

      case "DISDETTO":
        nomeTab = <Badge color="danger">{val}</Badge>
        break

      case "SOSPESO":
        nomeTab = <Badge color="warning">{val}</Badge>
        break

      case "SOSTITUITO":
        nomeTab = <Badge color="primary">{val}</Badge>
        break

      case "SCADUTO":
        nomeTab = <Badge color="dark">{val}</Badge>
        break

      default:
        nomeTab = <Badge color="dark">CONTRATTO NON CLASSIFICATO</Badge>
    }
    return nomeTab
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  //get lista clienti
  useEffect(() => {
    if (clients && !clients.length) {
      dispatch(getClients())
    }
  }, [dispatch, clients])

  //get contratti in base a selezione cliente da select
  useEffect(() => {
    if (client) {
      dispatch(getContracts(client.value, user.role))
      dispatch(getClientData(client.value))
    }
  }, [dispatch, client])

  //set lista clienti su select2
  useEffect(() => {
    setClientList(clients)
    setIsLoading(false)
  }, [clients])

  useEffect(() => {
    console.log("con", contracts)
  }, [contracts])

  /*useEffect(() => {
    if (contractsList && !contractsList.length){
      console.log(contractsList)
    }
  }, [contractsList])*/

  useEffect(() => {
    console.log("consoledata", client_data)
  }, [client_data])

  function handleMulti2(client) {
    setClient(client)
  }

  return (
    <div>
      {user && user.role !== "customer" && (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Home" breadcrumbItem="Clienti" />

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <CardTitle>Situazione Cliente</CardTitle>

                      <form>
                        <Row>
                          <Col lg="6" md="6" sm="6" xs="12">
                            <div className="mb-3">
                              {/* <Label>Ajax (remote data)</Label> */}
                              <Select
                                value={client}
                                onChange={val => {
                                  handleMulti2(val)
                                }}
                                options={clientList}
                                className="select2-selection"
                                isLoading={isLoading}
                              />
                              {client_data &&
                              Object.keys(client_data).length > 0 ? (
                                <>
                                  <CardText></CardText>
                                  <CardText>{client_data.an_indir}</CardText>
                                  <CardText>
                                    {client_data.an_cap} -{" "}
                                    {client_data.an_citta} (
                                    {client_data.an_prov})
                                  </CardText>
                                  <CardText>
                                    P.IVA {client_data.an_pariva} / C.F.{" "}
                                    {client_data.an_codfis != ""
                                      ? client_data.an_codfis
                                      : "-"}
                                  </CardText>
                                  <CardText></CardText>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Col>
                          <Col lg="6" md="6" sm="6" xs="12">
                            <div>
                              <RadarChart dataColors='["--bs-success-rgb, 0.2", "--bs-success", "--bs-primary-rgb, 0.2", "--bs-primary"]' />
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={4}></Col>
                <Col lg={4}></Col>
                <Col lg={4}>
                  <Card>
                    <CardBody>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Apri ticket
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "4",
                            })}
                            onClick={() => {
                              toggle1("4")
                            }}
                          >
                            Infrastruttura
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            Contratti
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "6",
                            })}
                            onClick={() => {
                              toggle1("6")
                            }}
                          >
                            Progetti
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "7",
                            })}
                            onClick={() => {
                              toggle1("7")
                            }}
                          >
                            Ric. Preventivi
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "8",
                            })}
                            onClick={() => {
                              toggle1("8")
                            }}
                          >
                            Offerte
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "9",
                            })}
                            onClick={() => {
                              toggle1("9")
                            }}
                          >
                            Impegni
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "10",
                            })}
                            onClick={() => {
                              toggle1("10")
                            }}
                          >
                            Ticket
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "11",
                            })}
                            onClick={() => {
                              toggle1("11")
                            }}
                          >
                            Solleciti
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "12",
                            })}
                            onClick={() => {
                              toggle1("12")
                            }}
                          >
                            PEC e Domini
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={activeTab1}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId="4">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">
                                Infrastruttura
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="5">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">
                                {client && contracts.length > 0 ? (
                                  <TableContainer
                                    columns={columns}
                                    data={contracts}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    customPageSize={50}
                                    className="custom-header-css"
                                  />
                                ) : (
                                  <></>
                                )}
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="6">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">Progetti</CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="7">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">
                                Ric. Preventivi
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="8">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">Offerte</CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="9">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">Impegni</CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="10">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">Ticket</CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="11">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">Solleciti</CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="12">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">PEC e Domini</CardText>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default Clienti
