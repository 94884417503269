import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_CONTRACTS,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_FAIL,
  GET_CLIENT_DATA,
  GET_CLIENT_DATA_SUCCESS,
  GET_CLIENT_DATA_FAIL
} from "./actionTypes"

export const getClients = () => ({
  type: GET_CLIENTS,
})

export const getClientsSuccess = value => ({
  type: GET_CLIENTS_SUCCESS,
  payload: value,
})

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
})

export const getContracts = (client, role) => ({
  type: GET_CONTRACTS,
  payload: {client, role},
})

export const getContractsSuccess = value => ({
  type: GET_CONTRACTS_SUCCESS,
  payload: value,
})

export const getContractsFail = error => ({
  type: GET_CONTRACTS_FAIL,
  payload: error,
})

export const getClientData = client => ({
  type: GET_CLIENT_DATA,
  payload: client
})

export const getClientDataSuccess = value => ({
  type: GET_CLIENT_DATA_SUCCESS,
  payload: value,
})

export const getClientDataFail = error => ({
  type: GET_CLIENT_DATA_FAIL,
  payload: error,
})